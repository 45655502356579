import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Messages from './Messages';
import Input from './Input';

console.disableYellowBox = true;
console.info = () => {};
console.warn = () => {};
console.error = () => {};

function randomColor() {
  return '#' + Math.floor(Math.random() * 0xffffff).toString(16);
}

function App() {
  const [messages, setMessages] = useState([]);
  const [member, setMember] = useState({
    clientData: {
      username: '',
      color: randomColor(),
      id: '',
    },
  });

  const droneRef = useRef(null);

  useEffect(() => {
if(member.clientData.username && !droneRef.current){
    droneRef.current = new window.Scaledrone('iKwf6CiDzJ9UD9P8');
    const drone = droneRef.current;

    drone.on('open', (error) => {
      if (error) {
        return console.error(error);
      }

      const updatedMember = { ...member };
      updatedMember.clientData.id = drone.clientId;
      setMember(updatedMember);
    });

    const room = drone.subscribe('probna_soba');
    room.on('data', (data) => {
      setMessages((messages) => [
          ...messages,
          { member: data.user, text: data.m },]);
      
    });
window.addEventListener('beforeunload', () => {
        drone.on('close', event => {
  // Connection has been closed
});
      });
}
  }, [member,messages]);

  const handleNameSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const updatedMember = { ...member };
    updatedMember.clientData.username = name;
    setMember(updatedMember);
  };

  const onSendMessage = (message) => {
    const updatedMember = { ...member };
    const value = {
      user: updatedMember,
      m: message,
    };
    droneRef.current.publish({
      room: 'probna_soba',
      message: value,
    });
  };

  if (!member.clientData.username) {
    return (
      <div className="name-input">
        <h1>What's your name?</h1>
        <form onSubmit={handleNameSubmit}>
          <input type="text" name="name" placeholder="Enter your name" />
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="App-header">
        <h1>Chat</h1>
      </div>
      <Messages messages={messages} currentMember={member} />
      <Input onSendMessage={onSendMessage} />
    </div>
  );
}

export default App;
